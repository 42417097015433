import React, { useEffect, useState } from 'react'
import axiosInstance from 'utils/AxiosInstance'
import "../../styles/components/analytics/Analytics.css"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import DriverList from './components/DriverList'
import { useUserContext } from 'globalComponents/AppContext'
import { IoMdClose } from 'react-icons/io';

ChartJS.register(ArcElement, Tooltip, Legend);

const Analytics = () => {
    // const { userData } = useUserContext()
    const userData = JSON.parse(sessionStorage.getItem("userData"))
    const [ franchiseData, setFranchiseData ] = useState()
    const [ driverList, setDriverList ] = useState()
    const [ isLoaded, setIsLoaded ] = useState(false)
    const [ showEarningsCard, setShowEarningsCard ] = useState(false)
    const [ earningsData, setEarningsData ] = useState()

    // window.location.reload();

  useEffect(() => {
    if (userData?.id && userData?.area && !isLoaded) {
        axiosInstance
        .get("all/show/franchise/")
        .then(res => {
            const data = res.data
            const filterred = data.filter((item) => item.id === userData.id)
            // console.log(filterred)
            setFranchiseData(filterred[0])
        })
        .catch(err => {
            console.log(err)
        })

        axiosInstance
          .get(`all/show/driver/`)
          .then(res => {
            const list = res.data;
            const filtered = list.filter(item => 
              item.area && item.area.toLowerCase() === userData.area.toLowerCase()
            );
            console.log(filtered, userData.area);
            setDriverList(filtered);
          })
          .catch(err => {
              console.log(err)
          })

        setIsLoaded(true)
    }
  }, [userData, isLoaded])

  const renderPieChart = (share) => {
    const data = {
      labels: ['Share', 'Remaining'],
      datasets: [
        {
          data: [share, 15 - share],
          backgroundColor: ['#fb331a', '#e0e0e0'],
          hoverBackgroundColor: ['#e12d18', '#d3d3d3'],
        },
      ],
    };
    const options = {
        plugins: {
          legend: {
            display: false, // Hides the legend
          },
          tooltip: {
            enabled: false, // Disables the tooltips
          },
        },
        cutout: '70%', // Creates a donut hole in the center
      };

    return <Doughnut data={data} options={options} />;
  };

  const handleViewClick = ( partnerName, share, earnings ) => {
    const earningsDataObject = {
      name: partnerName,
      share: share,
      earnings: earnings
    }
    
    setEarningsData(earningsDataObject)
    setShowEarningsCard(true)
  }

  return (
    <div className='w-full min-h-screen text-left py-6 px-12'>

      {/* <div className='w-full mb-5'>

        <div className='w-full h-36 py-4 flex items-center justify-start gap-6'>
          <div className='h-full aspect-video flex flex-col items-center justify-start py-4 bg-transparent rounded-2xl border-slate-200 border-4'>
            <p className='supermercado text-center text-nowrap'>
              Successful Transactions
            </p>
            <p className='text-2xl text-left pt-3 font-sans font-medium text-orange-600'>
              ₹ 15,000
            </p>
          </div>
        </div>
      </div> */}

      {franchiseData?.another_additional_details?.length !== 0 &&
      <p className='text-2xl font-semibold'>No. Of Partners</p>}

    {franchiseData?.another_additional_details?.length !== 0 &&
      <div className='w-full h-fit flex flex-wrap items-center justify-start gap-10 mt-8 mb-12'>
        {franchiseData && franchiseData.another_additional_details?.map((item, index) => (
          <div
            key={index}
            className='h-60 aspect-[1.2/1] rounded-2xl border-[1.5px] border-[#FB331A] flex flex-col items-center justify-between p-4'
          >
            {showEarningsCard &&
            <EarningsCard
              name={earningsData.name}
              share={earningsData.share}
              earnings={earningsData.earnings}
              setEarningsData={setEarningsData}
              setShowCard={setShowEarningsCard}
            />}
            <p className='text-xl font-medium capitalize text-orange-600'>
              {item.name}
            </p>
            <div className='h-[7rem] aspect-square relative'>
                {renderPieChart(item.percentage)}
                <p className='text-lg text-[#fb331a] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    {item.percentage}%
                </p>
            </div>
            <div className='text-center'>
              <p className='text-white font-bold font-lg px-8 py-1 bg-orange-600 rounded-full'>
                Earnings:
                <span className='ml-2 text-white text-opacity-90 font-semibold'>₹0</span>
                <span onClick={() => handleViewClick(item.name, item.percentage, 0)} className='ml-3 underline text-xs cursor-pointer font-normal'>
                  View
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>}

      {driverList && <DriverList driverList={driverList} />}
    </div>
  )
}

const EarningsCard = ({ name, share, earnings, setEarningsData, setShowCard }) => {

  const handleClose = () => {
    setEarningsData({})
    setShowCard(false)
  }

  return (
    <div className='left-0 top-0 z-[999] w-screen h-screen fixed bg-black bg-opacity-15 backdrop-blur-sm flex items-center justify-center'>
      <div className='w-[30rem] px-10 py-8 rounded-2xl bg-white relative'>
        <button onClick={handleClose} className='flex rounded-full text-white absolute top-3 right-3 p-1 items-center justify-center bg-black bg-opacity-40 text-xl'>
          <IoMdClose />
        </button>
        <div className='w-full flex items-center mb-4 border-b border-orange-600 pb-2 justify-between'>
          <p className='text-2xl font-bold capitalize'>
            {name}
          </p>
          <p className='bg-orange-600 rounded-full text-base px-5 py-1 text-white font-semibold'>
            Share: {share || 0}%
          </p>
        </div>
        <div className='flex items-center justify-center'>
          <p className='text-xl font-medium'>
            Earnings:   {earnings}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Analytics