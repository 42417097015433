import React from 'react'

const ErrorPage = () => {
  return (
    <div>
        something went wrong
    </div>
  )
}

export default ErrorPage