import React, { useEffect, useState } from 'react'
import axiosInstance from 'utils/AxiosInstance'
import ErrorPage from 'globalComponents/ErrorPage/ErrorPage'
import { useUserContext } from 'globalComponents/AppContext'
import DriverList from 'components/analytics/components/DriverList'

const AllDriversPage = () => {
    const userData = JSON.parse(sessionStorage.getItem("userData"))
    const [error, setError] = useState(false);
    const [ driverList, setDriverList ] = useState()

  useEffect(() => {
    if (userData) {
        axiosInstance
          .get(`all/show/driver/`)
          .then(res => {
            const list = res.data;
            const filtered = list.filter(item => 
              item.area && item.area.toLowerCase() === userData.area.toLowerCase()
            );
            console.log(filtered, userData.area);
            setDriverList(filtered);
          })
          .catch(err => {
              console.log(err)
          })
    }
  }, [userData])

  if (error) {
    return <ErrorPage />;
  }

  return (
    <div className='w-full min-h-screen text-left py-6 px-12'>
      {driverList && <DriverList driverList={driverList} area={userData.area} />}
    </div>
  )
}

export default AllDriversPage