import DriverAddition from 'components/DriverAddition/DriverAddition'
import React from 'react'

const DriverAdditionPage = () => {
  return (
    <>
        <DriverAddition />
    </>
  )
}

export default DriverAdditionPage