import { createContext, useContext, useEffect, useState } from 'react';
import axiosInstance from 'utils/AxiosInstance';

const AppContext = createContext();

export const UserProvider = ({ children }) => {
  const logged = sessionStorage.getItem("logged")
  const [ name, setName] = useState('');
  const [ userId, setUserId ] = useState()
  const [ loggedIn, setLoggedIn ] = useState(false)
  const [ userData, setUserData ] = useState(JSON.parse(sessionStorage.getItem("userData")))
  const [ alert, setAlert ] = useState()

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log(alert)
      setAlert(null);
    }, 5000);
  
    // Cleanup function to clear the timeout
    return () => clearTimeout(timer);
  }, [alert]);

  const [ driverList, setDriverList ] = useState()

    useEffect(() => {
      console.log(userId)
      if(logged && userId) {
          axiosInstance
          .get(`/all/franchise/${userId}/driverlist/`)
          .then(res => {
              // console.log(res)
              setDriverList(res.data)
          })
          .catch(err => {
              console.log(err)
          })
      }
    }, [logged, userId])

  useEffect(() => {
    if (userId) {
        sessionStorage.setItem("userId", userId)
    }
  }, [userId])

  return (
    <AppContext.Provider
      value={{
        name,
        setName,
        userId,
        setUserId,
        loggedIn,
        setLoggedIn,
        userData,
        setUserData,
        alert,
        setAlert,
        driverList,
      }}
    >
        {console.log(alert)}
      {children}
    </AppContext.Provider>
  );
};

export const useUserContext = () => useContext(AppContext);
